import Axios from "axios";
import {
    DELETE_USER_URL,
    GET_USER_WALLET_URL,
    LOGIN_OTP_USER_URL,
    REGISTER_USER_OTP_URL,
    SEND_CUSTOM_OTP_URL,
    UPDATE_USER_INFO_URL,
    USER_UPDATE_USER_INFO_URL,
    VIEW_ITEM_GROUP_ITEMS_URL,
    
} from './../../configs/index';
import {
    DELETE_USER,
    LOGIN_OTP_USER,
    LOGOUT_USER,
    REGISTER_USER_OTP,
    SEND_CUSTOM_OTP,
    UPDATE_USER_INFO,
    USER_UPDATE_USER_INFO,
    USER_WALLET,
    VIEW_ITEM_GROUP_ITEMS,
} from './actionType';


export const loginUserOtp = (
    otp,
    phone,
    address
) => (dispatch) => {
    Axios.post(LOGIN_OTP_USER_URL, {
        otp: otp,
        phone: phone,
        address: address,
    })
        .then((response) => {
            const user = response.data;
            console.log(user);
            return dispatch({ type: LOGIN_OTP_USER, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const registerUserOtp = (
    name,
    email,
    phone,
    dob,
    address
) => (dispatch) => {
    Axios.post(REGISTER_USER_OTP_URL, {
        name: name,
        email: email,
        phone: phone,
        dob: dob,
        address: address,
    })
        .then((response) => {
            const user = response.data;
            // console.log(user, 'register');
            return dispatch({ type: REGISTER_USER_OTP, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const sendCustomOtp = (phone_number) => (dispatch) => {
    return Axios.post(SEND_CUSTOM_OTP_URL, {
        phone: phone_number,
    })
        .then((response) => {
            const user = response.data;
            // console.log(user);
            return dispatch({ type: SEND_CUSTOM_OTP, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const viewItemGroupItems = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(VIEW_ITEM_GROUP_ITEMS_URL, {
        id: id,
        latitude: latitude,
        longitude: longitude,
    })
        .then((response) => {
            const itemGroup = response.data;
            // console.log(user);
            return dispatch({ type: VIEW_ITEM_GROUP_ITEMS, payload: itemGroup });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const updateUserInfo = (token) => (dispatch) => {
    return Axios.post(UPDATE_USER_INFO_URL, {
        token: token,
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: UPDATE_USER_INFO, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });
};
export const updateUserDetails = (formData, token) => (dispatch) => {
    return Axios.post(USER_UPDATE_USER_INFO_URL, formData, token, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })

        .then((response) => {
            const user = response.data;
            console.log(user)
            return dispatch({ type: USER_UPDATE_USER_INFO, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });
};
export const getUserWallet = (token) => (dispatch) => {
    return Axios.post(GET_USER_WALLET_URL, {
        token: token,
    })
        .then((response) => {
            const wallet = response.data;
            return dispatch({ type: USER_WALLET, payload: wallet });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const logoutUser = () => (dispatch) => {
    const user = [];
    // console.log(user, 'logout action')
    //remove geoLocation and userSetAddress
    localStorage.removeItem("userSetAddress");
    localStorage.removeItem("geoLocation");
    localStorage.removeItem("lastSavedNotificationToken");

    return [
        dispatch({
            type: LOGOUT_USER,
            payload: user,
        }),

    ];
};

export const deleteUser = (token) => (dispatch) => {
    return Axios.post(DELETE_USER_URL, {
        token,
    })
        .then((response) => {
            localStorage.clear();
            localStorage.setItem("SPLASH", true);

            const user = response.data;
            return dispatch({ type: DELETE_USER, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });


};