import React, { Component } from "react";
import {
  loadCart,
  removeProduct,
  calcualteStoreMaxDistance,
  getDeliverySlab,
  updateSlabDetails,
} from "../../../services/cart/actions";

import DelayLink from "../../helpers/delayLink";
import Ink from "react-ink";
import { connect } from "react-redux";
import { formatPrice } from "../../helpers/formatPrice";
import { updateCart } from "../../../services/total/actions";
import Dialog from "@material-ui/core/Dialog";
import { removeCoupon } from "../../../services/coupon/actions";
import { ChevronRight } from "react-iconly";
import Confetti from "react-confetti";
import { Discount } from "react-iconly";

class Cart extends Component {
  state = {
    isOpen: false,
    removeProductFromPreviousRestaurant: false,
    open: false,
    product: [],
    confetti: false,
    discount_loading: false,
  };

  componentDidMount() {
    const { cartProducts } = this.props;
    if (cartProducts.length) {
      this.setState({ isOpen: true });
    }
    if (
      this.props.maxDistanceStore &&
      this.props.maxDistanceStore.store_id &&
      this.props.maxDistanceStore.store_id.city
    ) {
      this.props
        .getDeliverySlab(
          this.props.maxDistanceStore.store_id.city.id,
          this.props.maxDistanceStore.distance
        )
        .then((response) => {
          this.checkDeliverySlab();
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }

    if (
      this.props.maxDistanceStore &&
      this.props.maxDistanceStore.store_id &&
      this.props.maxDistanceStore.store_id.city &&
      this.props.maxDistanceStore !== nextProps.maxDistanceStore
    ) {
      this.props
        .getDeliverySlab(
          this.props.maxDistanceStore.store_id.city.id,
          this.props.maxDistanceStore.distance
        )
        .then((response) => {
          this.checkDeliverySlab();
        this.setState({ discount_loading: false });

        });
    }
    if (
      nextProps.current_slab &&
      this.props.current_slab !== nextProps.current_slab &&
      nextProps.next_slab
    ) {
      if (
        parseInt(
          this.props.current_slab ? this.props.current_slab.bucket_price_to : 0
        ) < parseInt(nextProps.current_slab.bucket_price_to)
      ) {
        this.setState({ confetti: true });
        this.setState({ discount_loading: false });

        setTimeout(() => {
          this.setState({ confetti: false });
        }, 5000);
      }
    }
    // this.checkDeliverySlab();
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };

  handlePopup = () => {
    this.setState({ open: !this.state.open });
  };

  addProduct = (product) => {
    const { cartProducts, updateCart, user } = this.props;
    //get restaurant id and save to localStorage as active restaurant

    localStorage.setItem("cleared", "false");
    let productAlreadyInCart = false;
    let differentRestaurant = false;
    let cartUpdateflag = true;
    this.setState({ discount_loading: true });
    if (cartProducts.length > 0) {
    } else {
      // this.setState({ confetti: true });
      // setTimeout(() => {
      //   this.setState({ confetti: false });
      // }, 2500);
    }
    cartProducts.forEach((cp) => {
      if (cartUpdateflag) {
        if (product.store_id === cp.store_id) {
          localStorage.setItem("activeRestaurant", product.store_id);
        }
        // first check if the restaurent id matches with items in cart
        // if restaurant id doesn't match, then remove all products from cart
        // then continue to add the new product to cart
        if (cp.store_id === product.store_id) {
          // then add the item to cart or increment count
          if (cp.id === product.id) {
            //check if product has customizations, and if the customization matches with any
            if (
              JSON.stringify(cp.selectedaddons) ===
              JSON.stringify(product.selectedaddons)
            ) {
              // increment the item quantity by 1
              if (cp.quantity < product.stock) {
                cp.quantity += 1;
              }
              productAlreadyInCart = true;
              differentRestaurant = false;
            }
          }
        } else {
          // else if restaurant id doesn't match, then remove all products from cart
          this.setState({
            removeProductFromPreviousRestaurant: true,
            open: true,
          });

          differentRestaurant = true;
          cartUpdateflag = false;

          this.setState({ product: product });
        }
      }
    });
    if (product.stock > 0) {
      if (!productAlreadyInCart && !differentRestaurant) {
        localStorage.setItem("activeRestaurant", product.store_id);
        cartProducts.push(product);
      }

      if (cartUpdateflag) {
        updateCart(cartProducts);
      }
      this.props
        .calcualteStoreMaxDistance(
          user.success && user.data.id,
          cartProducts,
          user.success
            ? user.data.default_address
            : JSON.parse(localStorage.getItem("userSetAddress"))
        )
        .then((response) => {
          if (response && response.payload) {
            this.setState({
              max_distance: response.payload.distance,
              store: response.payload.store_id,
            });
          }

          // this.checkDeliverySlab();
        });
      this.openFloatCart();
    }
  };
  checkDeliverySlab = () => {
    const { slabs, cartTotal } = this.props;
    // console.log(slabs);
    // console.log(total)
    // var all_slabs = J
    if (slabs && slabs.success && slabs.sub_slabs) {
      var index = slabs.sub_slabs.findIndex(function(element) {
        return (
          element.bucket_price_from <= cartTotal.totalPrice &&
          element.bucket_price_to > cartTotal.totalPrice
        );
      });
      //   console.log(slabs.sub_slabs[index + 1]);

      console.log(index);
      if (index >= 0) {
        var current_slab = slabs.sub_slabs[index];
        var next_slab = slabs.sub_slabs[index + 1];

        var length = slabs.sub_slabs.length;
        if (
          cartTotal.totalPrice > slabs.sub_slabs[length - 1].bucket_price_to
        ) {
          current_slab = slabs.sub_slabs[length - 1];
          next_slab = undefined;
        }

        // console.log(next_slab);
        this.props.updateSlabDetails(current_slab, next_slab);
      }
    } else {
      current_slab = undefined;
      next_slab = undefined;
      // setTimeout(() => {

      this.props.updateSlabDetails(current_slab, next_slab);
      // }, 200);

    }
  };

  removeProduct = (product) => {
    const { cartProducts, updateCart, user } = this.props;

    const index = cartProducts.findIndex((p) => p.id === product.id);

    //if product is in the cart then index will be greater than 0
    if (index >= 0) {
      cartProducts.forEach((cp) => {
        if (cp.id === product.id) {
          if (cp.quantity === 1) {
            //if quantity is 1 then remove product from cart
            cartProducts.splice(index, 1);
          } else {
            //else decrement the quantity by 1
            cp.quantity -= product.quantity;
          }
        }
      });

      updateCart(cartProducts);
      this.props
        .calcualteStoreMaxDistance(
          user.success && user.data.id,
          cartProducts,
          user.success
            ? user.data.default_address
            : JSON.parse(localStorage.getItem("userSetAddress"))
        )
        .then((response) => {
          if (response && response.payload) {
            this.setState({
              max_distance: response.payload.distance,
              store: response.payload.store_id,
            });
          }

          // this.checkDeliverySlab();
        });
      if (cartProducts.length < 1) {
        this.closeFloatCart();
        localStorage.removeItem("activeRestaurant");
      }
    }
  };

  getTotalItemsInCart = () => {
    // if (localStorage.getItem("countQuantityAsTotalItemsOnCart") === "true") {
    // 	let total = 0;
    // 	this.props.cartProducts.forEach((item) => {
    // 		total += item.quantity;
    // 	});
    // 	return total;
    // } else {
    return this.props.cartTotal.productQuantity;
    // }
  };

  clearCart = () => {
    const { cartProducts, updateCart, removeCoupon } = this.props;
    cartProducts.splice(0, cartProducts.length);
    this.closeFloatCart();
    removeCoupon();
    setTimeout(() => {
      updateCart(cartProducts);
    }, 500);
    this.addProduct(this.state.product);
    this.openFloatCart();
    this.setState({ open: !this.state.open, product: [] });
    localStorage.setItem("cleared", "true");
  };

  __getDeliveryDiscount = (delivery_charge, delivery_charge_cust) => {
    let discont = parseFloat(delivery_charge - delivery_charge_cust).toFixed(2);
    // this.setState({ discount_loading: false });
    localStorage.setItem('delivery_discount', discont);

    return discont;
  };
  render() {
    const {
      cartTotal,
      cartProducts,
      slabs,
      current_slab,
      next_slab,
    } = this.props;

    let classes = ["float-cart"];

    if (!!this.state.isOpen) {
      classes.push("float-cart--open");
    }

    return (
      <React.Fragment>
        {this.state.confetti && (
          <Confetti
            width={500}
            confettiSource={{ x: 30, y: 90, w: 500, h: 5000 }}
            gravity={0.3}
            friction={0.99}
            numberOfPieces={500}
            height={5000}
          />
        )}
        {this.state.removeProductFromPreviousRestaurant && (
          <React.Fragment>
            <Dialog
              fullWidth={true}
              fullScreen={false}
              open={this.state.open}
              onClose={this.state.handlePopup}
              style={{ width: "100%", margin: "auto" }}
              PaperProps={{
                style: { backgroundColor: "#fff", borderRadius: "4px" },
              }}
            >
              <div
                className="container "
                style={{ borderRadius: "5px", height: "30%" }}
              >
                <React.Fragment>
                  <div className="px-10 col-12 py-3 d-flex justify-content-between align-items-center">
                    <h1 className="mt-2 mb-0 font-weight-black h4">
                      Replace items already in the cart?
                    </h1>
                  </div>
                  <div className="px-10 mb-20">
                    Your cart contains items from other store. Do you want to
                    discard the selection and add items from this store?
                  </div>
                  <div className="d-flex justify-content-center pb-4">
                    <div className="text-center mr-4">
                      <button
                        className="btn clear-cart-btn"
                        onClick={this.handlePopup}
                        style={{ borderColor: "#ce0060", color: "#ce0060" }}
                      >
                        NO
                      </button>
                    </div>

                    <div className="text-center">
                      <button
                        className="btn clear-cart-btn text-white"
                        onClick={this.clearCart}
                        style={{
                          backgroundColor: "#ce0060",
                          borderColor: "#ce0060",
                        }}
                      >
                        YES
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </Dialog>
          </React.Fragment>
        )}
        {/* {slabs &&
          slabs.success &&
          slabs.sub_slabs &&
          slabs.sub_slabs.length !== 0 
          && (
          <div className="mt-2" >
            <div
              className="border-radius-10 px-3 py-3 my-2"
              style={{
                backgroundColor: "#36c583",
              }}
            >
              <div className="d-flex align-items-center ">
                <Discount
                  set="light"
                  primaryColor="#fff"
                  secondaryColor="#fff"
                  size={30}
                />

                <div className="ms-2 d-flex flex-column justify-content-center align-items-center">
                  {current_slab &&
                  current_slab.delivery_charge_cust &&
                  current_slab.delivery_charge ? (
                    <span className=" fw-bd" style={{ color: "#fff" }}>
                      You are saving ₹{" "}
                      {parseFloat(current_slab.delivery_charge -
                        current_slab.delivery_charge_cust).toFixed(2)}{" "}
                      from delivery charge
                    </span>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {next_slab &&
                  next_slab.delivery_charge_cust &&
                  next_slab.bucket_price_from &&
                  next_slab.delivery_charge ? (
                    <span
                      className=" fw-bd"
                      style={{  color: "#fff" }}
                    >
                      Add item worth {next_slab.bucket_price_from} to get discount ₹
                      {parseFloat(next_slab.delivery_charge -
                        next_slab.delivery_charge_cust).toFixed(2)}{" "}
                    </span>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
		)} */}
        {this.state.isOpen &&
          slabs &&
          slabs.success &&
          slabs.sub_slabs &&
          slabs.sub_slabs.length !== 0 && (
            <div
              style={{
                position: "fixed",
                bottom: "6rem",
                left: "5vw",
                width: "90vw",
              }}
              className=""
            >
              <div
                style={{
                  backgroundColor: "#36c583",
                  borderRadius: "10px 10px 0px 0px ",
                }}
                className=" px-3 py-2"
              >
                {current_slab &&
                current_slab.delivery_charge_cust &&
                current_slab.delivery_charge ? (
                  <React.Fragment>
                    {this.state.discount_loading ? (
                      <React.Fragment>
                        <span className="fw-bd" style={{ color: "#fff" }}>
                          Checking for dicounts...
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {this.__getDeliveryDiscount(
                          current_slab.delivery_charge,
                          current_slab.delivery_charge_cust
                        ) > 0 && (
                          <span className=" fw-bd" style={{ color: "#fff" }}>
                            You are saving ₹{" "}
                            {this.__getDeliveryDiscount(
                              current_slab.delivery_charge,
                              current_slab.delivery_charge_cust
                            )}{" "}
                            from delivery charge. &nbsp;
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
                {next_slab &&
                next_slab.delivery_charge_cust &&
                next_slab.bucket_price_from &&
                next_slab.delivery_charge ? (
                  <React.Fragment>
                    {this.state.discount_loading ? (
                      <React.Fragment>
                        
                      </React.Fragment>
                    ) : (
                      <span className=" fw-bd" style={{ color: "#fff" }}>
                        Add item worth {next_slab.bucket_price_from} to get
                        discount ₹
                        {parseFloat(
                          next_slab.delivery_charge -
                            next_slab.delivery_charge_cust
                        ).toFixed(2)}{" "}
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            </div>
          )}

        <div
          className={classes.join(" ")}
          style={{
            background: "linear-gradient(90deg, #E52881 50%, #AC2363 100%)",
          }}
        >
          {cartProducts.length ? (
            <DelayLink to={"/cart"} delay={200} className="text-white">
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <div>
                  <div style={{ fontWeight: "100", fontSize: "11px" }}>
                    {this.getTotalItemsInCart()} Items
                  </div>
                  <div style={{ fontWeight: "700", fontSize: "14px" }}>
                    ₹ {formatPrice(cartTotal.totalPrice)}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-2">View Cart</div>
                  <ChevronRight />
                </div>
              </div>
              <Ink duration="500" />
            </DelayLink>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  cartTotal: state.total.data,
  user: state.user.user,
  maxDistanceStore: state.cart.max_distance,
  slabs: state.cart.slabs,
  current_slab: state.cart.current_slab,
  next_slab: state.cart.next_slab,
});

export default connect(mapStateToProps, {
  loadCart,
  updateCart,
  removeProduct,
  removeCoupon,
  calcualteStoreMaxDistance,
  getDeliverySlab,
  updateSlabDetails,
})(Cart);
