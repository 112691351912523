import React, { Component } from 'react';
import { ChevronLeft, } from 'react-iconly';
import Ink from 'react-ink';
import { withRouter } from 'react-router-dom';

class Hoveredbackbutton extends Component {
    static contextTypes = {
        router: () => null
    };
    render() {
        return (
            <React.Fragment>

                <div className="hover-back-btn">
                    <div className="card " style={{ borderRadius: '10px' }}>
                        <div className="p-0">
                            <div className='d-flex flex-row '>
                                <div className=' '
                                // style={{ borderRadius: '10px', position: 'relative' }}
                                >
                                    <button className='btn btn-sm btn-icon btn-light ' style={{ background: 'none', outline: 'none', border: 'none', borderRadius: '10px' }} type='button'
                                        onClick={this.props.history.goBack}
                                    >
                                        <ChevronLeft set='curved' size="small" primaryColor='#000000' />
                                        <Ink duration={500} style={{ color: '#AAAAAA' }} />
                                    </button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Hoveredbackbutton);
