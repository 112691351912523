/*-disable no-unreachable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  WEBSITE_CDN_URL,
  WEBSITE_URL,
} from "../../../../configs/website/index";
import ProgressiveImage from "react-progressive-image";
import {
  getTrendingBrands,
  getNearBrandStore,
} from "../../../../services/home/actions";

class TrendingBrands extends Component {
  state = {};

  componentDidMount() {
    const address = JSON.parse(localStorage.getItem("userSetAddress"));
    this.props.getTrendingBrands(address.lat, address.lng);
  }
  viewBrandStore = (id) => {
    const address = JSON.parse(localStorage.getItem("userSetAddress"));
    this.props
      .getNearBrandStore(id, address.lat, address.lng)
      .then((response) => {
        if (response.payload.store) {
          this.props.history.push("/single-store/" + response.payload.store.id);
        } else {
          this.props.history.push("/single-brand/" + id);
        }
      });
  };

  render() {
    const { trending_brands } = this.props;
    const colors = [
      "#FEEAC7",
      "#F4F99A",
      "#E4E1FF",
      "#EEFE9E",
      "#FFE9E9",
      "#FBB4B4",
    ];
    function random_item(items) {
      return items[Math.floor(Math.random() * items.length)];
    }
    return (
      <React.Fragment>
        {trending_brands &&
        trending_brands.brands &&
        trending_brands.brands.length > 0 ? (
          <React.Fragment>
            <div className="mt-20">
              <div style={{ fontWeight: "bolder", fontSize: "18px" }}>
                {localStorage.getItem("popular_brands_heading")}
              </div>
              <div
                className="pb-0"
                style={{
                  display: "grid",
                  gridTemplateRows: "auto auto",
                  gridAutoFlow: "column",
                  overflowX: "scroll",
                  columnGap: "12px",
                  height: "auto",
                  alignItems: "center",
                }}
              >
                {trending_brands.brands.map((item) => (
                  <div key={item.id}>
                    <div
                      className="position-relative border-radius-10 "
                      onClick={() => {
                        this.viewBrandStore(item.id);
                      }}
                    >
                      {/* <Ink duration={500} style={{ color: '#aaaaaa' }} /> */}
                      <div
                        className="d-flex flex-row justify-content-center align-items-center text-center"
                        style={
                          item.image2
                            ? {
                                width: "32vw",
                                height: "20vw",
                                backgroundImage: `url(${WEBSITE_CDN_URL +
                                  item.image2})`,
                                backgroundRepeat: "round",
                                borderRadius: "1rem",
                                marginTop: "14vw",
                              }
                            : {
                                width: "32vw",
                                height: "20vw",
                                backgroundImage: `url(https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder)`,
                                backgroundRepeat: "round",
                                borderRadius: "1rem",
                                marginTop: "14vw",
                              }
                        }
                      >
                        <ProgressiveImage
                          delay={20}
                          src={WEBSITE_CDN_URL + item.image}
                          placeholder={
                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                          }
                        >
                          {(src, loading) => (
                            <img
                              src={src}
                              className="trending-brand-image"
                              alt={item.name}
                            />
                          )}
                        </ProgressiveImage>
                        <span
                          className="pt-5"
                          style={
                            item.popular_text_color
                              ? {
                                  color: item.popular_text_color,
                                  fontWeight: "700",
                                  fontSize: "11px",
                                }
                              : { fontWeight: "700", fontSize: "11px" }
                          }
                        >
                          {item.name}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div>
            {/* <ContentLoader
                            width={420}
                            // height={10}
                            viewBox="0 0 420 300"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        > */}
            {/* Only SVG shapes */}
            {/* <rect x="0" y="20" rx="5" ry="5" width="90vw" height="5vw" />

                        <rect x="0" y="60" rx="15" ry="15" width="29vw" height="24vw" />
                        <rect x="32vw" y="60" rx="15" ry="15" width="29vw" height="24vw" />
                        <rect x="64vw" y="60" rx="15" ry="15" width="29vw" height="24vw" />

                        <rect x="0" y="42vw" rx="15" ry="15" width="29vw" height="24vw" />
                        <rect x="32vw" y="42vw" rx="15" ry="15" width="29vw" height="24vw" />
                        <rect x="64vw" y="42vw" rx="15" ry="15" width="29vw" height="24vw" /> */}
            {/* </ContentLoader> */}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  trending_brands: state.home.trending_brands,
});

export default withRouter(
  connect(mapStateToProps, {
    getTrendingBrands,
    getNearBrandStore,
  })(TrendingBrands)
);
