import React, { Component } from "react";
import Ink from "react-ink";
import { Location, Search } from 'react-iconly';

class Header extends Component {

	static contextTypes = {
		router: () => null,
	};

	render() {
		return (
			<React.Fragment>
				<div className="col-12 p-0" style={{ backgroundColor: '#F8FBFF', borderRadius: '0.5rem' }}>
					<div className="m-0">
						<div className="block-content p-0">
							<div className="input-group search-box">
								<div className="input-group-append">
									<button
										type="submit"
										className="btn nav-location truncate-text"
										style={{ position: "relative", color: 'black', display: 'flex', alignItems: 'center', width: '75vw' }}
										onClick={() => {
											this.context.router.history.push("/search-location")
										}}
									>
										<Location />
										{localStorage.getItem("userSetAddress") && (
											<React.Fragment>
												<span>
													{JSON.parse(localStorage.getItem("userSetAddress")).tag !== null ? (
														<strong className="text-uppercase mr-1">
															{JSON.parse(localStorage.getItem("userSetAddress")).tag}
														</strong>
													) : null}

													{JSON.parse(localStorage.getItem("userSetAddress")).house !==
														null ? (
														<span>
															{JSON.parse(localStorage.getItem("userSetAddress")).house
																.length > 12
																? `${JSON.parse(
																	localStorage.getItem("userSetAddress")
																).house.substring(0, 12)}...`
																: JSON.parse(localStorage.getItem("userSetAddress"))
																	.house}
														</span>
													) : (
														<span>
															{JSON.parse(localStorage.getItem("userSetAddress")).address
																.length > 18
																? `${JSON.parse(
																	localStorage.getItem("userSetAddress")
																).address.substring(0, 27)}...`
																: JSON.parse(localStorage.getItem("userSetAddress"))
																	.address}
														</span>
													)}
												</span>
											</React.Fragment>
										)}
										<Ink duration="500" />
									</button>
									<button
										type="submit"
										className="btn nav-location truncate-text"
										style={{ position: "relative", color: 'black', display: 'flex', alignItems: 'center', width: '15vw' }}
										onClick={() => {
											this.context.router.history.push("/search")
										}}
									>
										<div style={{ position: 'absolute', right: '0px' }}>
											<Search primaryColor="#CE0060" />
										</div>
										<Ink duration="500" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Header;