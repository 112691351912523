import React, { Component } from 'react';
import { WEBSITE_CDN_URL, } from '../../../../configs/website/index';
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import ContentLoader from 'react-content-loader';
import { Bookmark, Star, TimeCircle } from 'react-iconly';
import { Carousel } from 'react-responsive-carousel';
import HoveredBackButton from '../../../Elements/HoveredBackButton';
import ItemBanners from "./../ItemBanners/index";

class Stores extends Component {
    getMarkedup = (description) => {
        return { __html: description };
    }
    render() {
        const { stores } = this.props;
        return (
            <React.Fragment>
                {stores ? (
                    <React.Fragment>
                        {stores.length > 0 &&
                            <div className="mt-10">
                                <div style={{ fontWeight: 'bolder', fontSize: '18px' }}>{localStorage.getItem('stores_heading')}</div>
                                <div>
                                    {stores.map((item, index) => (
                                        <div key={item.id} className="mt-4">
                                            {index == '2' &&
                                                <div className="mb-4">
                                                    <ItemBanners position={'SECOND'} />
                                                </div>
                                            }
                                            <Link to={'/single-store/' + item.id}>
                                                <div className="d-flex flex-column " style={{ boxShadow: '0px 0px 5px #b0b0b0', borderRadius: '10px' }}>
                                                    <div>
                                                        <Carousel centerMode={false} showIndicators={false} dots={false} showThumbs={false} centerSlidePercentage={95} infiniteLoop={false} labels={false} showStatus={false} showArrows={false}
                                                            preventMovementUntilSwipeScrollTolerance
                                                            swipeScrollTolerance={100}>

                                                            <ProgressiveImage
                                                                delay={20}
                                                                src={WEBSITE_CDN_URL + item.image}
                                                                placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                                            >
                                                                {(src, loading) => (
                                                                    <img
                                                                        src={src}
                                                                        className=""
                                                                        alt={item.name}
                                                                        style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                                    />
                                                                )}
                                                            </ProgressiveImage>

                                                            <ProgressiveImage
                                                                delay={20}
                                                                src={WEBSITE_CDN_URL + item.image2}
                                                                placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                                            >
                                                                {(src, loading) => (
                                                                    <img
                                                                        src={src}
                                                                        className=""
                                                                        alt={item.name}
                                                                        style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                                    />
                                                                )}
                                                            </ProgressiveImage>

                                                            <ProgressiveImage
                                                                delay={20}
                                                                src={WEBSITE_CDN_URL + item.image3}
                                                                placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                                            >
                                                                {(src, loading) => (
                                                                    <img
                                                                        src={src}
                                                                        className=""
                                                                        alt={item.name}
                                                                        style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                                    />
                                                                )}
                                                            </ProgressiveImage>
                                                        </Carousel>
                                                    </div>

                                                    <div className="d-flex flex-column px-3 py-3" style={{ backgroundColor: '#e8e8e8', borderRadius: '0px 0px 10px 10px' }}>
                                                        <div className="" style={{ fontSize: '1em' }}>
                                                            <div className='d-flex flex-row justify-content-around align-items-center'>
                                                                <div className='flex-shrink-1'>

                                                                    <span className='fw-bold ' style={{ fontSize: '1.1em' }}>{item.name} </span>
                                                                </div>
                                                                <div className='flex-grow-1 ms-2 mt-3 text-truncate'>
                                                                    <span className='' style={{ fontSize: '0.8em' }} dangerouslySetInnerHTML={this.getMarkedup(item.description)}>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            {/* <Truncate lines={1} ellipsis={<span >...</span>}> */}

                                                            {/* </Truncate> */}

                                                        </div>
                                                        <div className='d-flex flex-row align-items-center mt-2'>

                                                            <div className="px-2 py-1" style={{ fontSize: '.8em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                                {item.avg_time ?
                                                                    <div className='' >
                                                                        <TimeCircle size="small" style={{ color: '#ed1c24' }} />
                                                                        <span style={{ color: '#342e86' }}> {item.avg_time} Minutes</span>
                                                                    </div>
                                                                    :

                                                                    <div className='' >

                                                                        <span style={{ color: '#342e86' }} ><TimeCircle className="" style={{ fontSize: '0.8em', color: '#ed1c24' }} /> 30 Minutes</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {item.rating ?
                                                                <div className="px-2 py-1 ms-3" style={{ fontSize: '.8em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                                    {item.rating <= 1.9 ?
                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                        :
                                                                        item.rating <= 2.9 ?
                                                                            <>

                                                                                <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                            </>
                                                                            :
                                                                            item.rating <= 3.9 ?
                                                                                <>
                                                                                    <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                    <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                    <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                </>
                                                                                :
                                                                                item.rating <= 4.9 ?
                                                                                    <>
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                        <Star size="small" style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                                    </>
                                                                    }


                                                                </div>
                                                                :
                                                                <>

                                                                </>
                                                            }


                                                            <div className="px-2 py-1 text-center ms-3" style={{ fontSize: '.8em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                                <span style={{ color: '#342e86' }}><Bookmark size="small" style={{ fontSize: '1em', color: '#ed1c24' }} />  Popular</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                ) : (
                    <>
                        <HoveredBackButton />
                        <ContentLoader
                            width={420}
                            // height={10}
                            viewBox="0 0 420 280"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            {/* Only SVG shapes */}
                            <rect x="0" y="20" rx="5" ry="5" width="90vw" height="5vw" />

                            <rect x="0" y="60" rx="15" ry="15" width="44vw" height="30vw" />
                            <rect x="47vw" y="60" rx="15" ry="15" width="44vw" height="30vw" />

                            <rect x="0" y="50vw" rx="15" ry="15" width="44vw" height="30vw" />
                            <rect x="47vw" y="50vw" rx="15" ry="15" width="44vw" height="30vw" />
                        </ContentLoader>
                    </>
                )}
            </React.Fragment>
        );
    }
}

export default Stores;
