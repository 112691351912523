import React, { Component } from "react";
import { connect } from "react-redux";
import { WEBSITE_CDN_URL } from "../../../../configs/website/index";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { getStoreSliders } from "../../../../services/home/actions";
import ContentLoader from "react-content-loader";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HoveredBackButton from "../../../Elements/HoveredBackButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

class StoreSliders extends Component {
  componentDidMount() {
    this.props.getStoreSliders(
      JSON.parse(localStorage.getItem("userSetAddress")).lat,
      JSON.parse(localStorage.getItem("userSetAddress")).lng
    );
  }

  render() {
    const { store_sliders } = this.props;

    return (
      <React.Fragment>
        {store_sliders && store_sliders.sliders ? (
          <React.Fragment>
            {store_sliders && store_sliders.sliders.length > 0 && (
              <div className="mt-20 mb-30">
                <div
                  className=" user-select-none mb-20"
                  style={{ fontWeight: "bolder", fontSize: "18px" }}
                >
                  {localStorage.getItem("store_slider_heading")}
                </div>
                <div
                  className="d-flex mt-15"
                  style={{
                    overflowX: "scroll",
                    overflowY: "smooth",
                    position: "relative",
                    height: "auto",
                  }}
                >
                  {store_sliders &&
                    store_sliders.sliders.map((item, index) => (
                    <Link to={"/slider-stores/" + item.id} key={item.id}>
                      <div className="me-3">
                        <ProgressiveImage
                          delay={20}
                          src={WEBSITE_CDN_URL + item.image}
                          placeholder={
                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                          }
                        >
                          {(src, loading) => (
                            <img
                              src={src}
                              style={{
                                width: "60vw",
                                height: "60vw",
                                objectFit: "cover",
                                borderRadius: "0.5rem",
                              }}
                              alt={item.name}
                            />
                          )}
                        </ProgressiveImage>
                      </div>
                    </Link>
                  ))}
                </div>
                {/* <Carousel
                  infiniteLoop={false}
                  autoPlay={false}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={true}
                  centerMode={true}
                  centerSlidePercentage={70}
                  preventMovementUntilSwipeScrollTolerance
                  // swipeScrollTolerance={100}
                  transitionTime={500}
                >
                  {store_sliders &&
                    store_sliders.sliders.map((item) => (
                      <Link to={"/slider-stores/" + item.id} key={item.id}>
                        <div className="me-3">
                          <ProgressiveImage
                            delay={20}
                            src={WEBSITE_CDN_URL + item.image}
                            placeholder={
                              "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                            }
                          >
                            {(src, loading) => (
                              <img
                                src={src}
                                style={{
                                  width: "60vw",
                                  height: "60vw",
                                  objectFit: "cover",
                                  borderRadius: "0.5rem",
                                }}
                                alt={item.name}
                              />
                            )}
                          </ProgressiveImage>
                        </div>
                      </Link>
                    ))}
                </Carousel> */}
              </div>
            )}
          </React.Fragment>
        ) : (
          <>
            <HoveredBackButton />
            <ContentLoader
              width={420}
              // height={10}
              viewBox="0 0 420 250"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {/* Only SVG shapes */}
              <rect x="0" y="10" rx="5" ry="5" width="65vw" height="50vw" />
              <rect x="280" y="10" rx="5" ry="5" width="40vw" height="50vw" />
            </ContentLoader>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store_sliders: state.home.store_sliders,
});

export default connect(mapStateToProps, {
  getStoreSliders,
})(StoreSliders);
