import React, { Component } from "react";

class Desktop extends Component {
    render() {
        return (
            <React.Fragment>
                <iframe
                    src={'https://superjoe.in/public/web/'}
                    frameBorder="0"
                    style={{
                        height: "100vh",
                        width: "100vw",
                        pointerEvents: "auto",
                        
                    }}
                />
                {/* Loading... */}
            </React.Fragment>
        );
    }
}

export default Desktop;
