import React, { Component } from "react";
import animationData from '../../assets/images/loader.json';
import Lottie from 'react-lottie';

class Loading extends Component {
	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice"
			}
		};
		return (
			<React.Fragment>
				<div className="height-100 overlay-loading ongoing-payment-spin">
					<Lottie
						options={defaultOptions}
						height={200}
						width={200}
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default Loading;
