import React, { Component } from "react";
import FirstScreen from "../Mobile/FirstScreen";
import Home from "../Mobile/Home";
import Desktop from "../Desktop";

class App extends Component {

	render() {

		const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);

		if (!window.ReactNativeWebView && localStorage.getItem('redirection') != 'ONLINE') {
		// if (!window.ReactNativeWebView) {
			// this.props.history.replace("/online");

			if (isIOS) {
				window.location.href = "https://apps.apple.com/in/app/superjoe/id1622001248";
			} else {
				window.location.href = "https://play.google.com/store/apps/details?id=com.superjoe";
			}
		}

		// setTimeout(() => {
		// 	console.log('11111111111111111');
		// }, 30000);

		return (
			<React.Fragment>
				{window.innerWidth <= 1024 ? (
					<React.Fragment>
						{localStorage.getItem("userSetAddress") ? (
							<Home />
						) : (
							<FirstScreen />
						)}
					</React.Fragment>
				) : (
					<Desktop />
				)}
			</React.Fragment>
		);
	}
}

export default App;
