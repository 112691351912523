import {
  GET_ITEM_GROUPS,
  GET_ITEM_BANNERS,
  GET_TRENDING_BRANDS,
  GET_STORE_SLIDERS,
  GET_POPULAR_ITEMS,
  GET_STORES,
  SERACH_ITEMS,
  SINGLE_STORE,
  SINGLE_ITEM,
  VIEW_LOYALTY,
  BRAND_STORES,
  GET_NEAR_BRAND_STORE,
  VIEW_MEMBERSHIP,
  JOIN_MEMBERSHIP,
  GET_MEMBERSHIP_USER,
  MEMBERSHIP_SUCCESS,
  SCRATCH_MILESTONE,
  USER_MILESTONE,
} from "./actionTypes";
import {
  GET_ITEM_GROUPS_URL,
  GET_ITEM_BANNERS_URL,
  GET_TRENDING_BRANDS_URL,
  GET_STORE_SLIDERS_URL,
  GET_POPULAR_ITEMS_URL,
  GET_STORES_URL,
  SEARCH_ITEMS_URL,
  VIEW_SINGLE_STORE_URL,
  VIEW_LOYALTY_URL,
  VIEW_SINGLE_ITEM_URL,
  GET_BRAND_STORES,
  GET_NEAR_BRAND_STORE_URL,
  VIEW_MEMBERSHIP_URL,
  JOIN_MEMBERSHIP_URL,
  GET_MEMBERSHIP_USER_URL,
  MEMBERSHIP_SUCCESS_URL,
  SCRATCH_MILESTONE_URL,
  USER_MILESTONE_URL,
} from "../../configs";
import Axios from "axios";

export const viewLoyalty = (token) => (dispatch) => {
  Axios.post(VIEW_LOYALTY_URL, {
    token: token,
  })
    .then((response) => {
      const loyalty = response.data;
      return dispatch({ type: VIEW_LOYALTY, payload: loyalty });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getMembershipUser = (token) => (dispatch) => {
  return Axios.post(GET_MEMBERSHIP_USER_URL, {
    token,
  })
    .then((response) => {
      const membership_user = response.data.data;
      return dispatch({ type: GET_MEMBERSHIP_USER, payload: membership_user });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const viewMembership = (token) => (dispatch) => {
  Axios.post(VIEW_MEMBERSHIP_URL, {
    token: token,
  })
    .then((response) => {
      const memberships = response.data.memberships;
      return dispatch({ type: VIEW_MEMBERSHIP, payload: memberships });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const joinMembership = (token,id) => (dispatch) => {
  Axios.post(JOIN_MEMBERSHIP_URL, {
    token: token,
	  id:id
	})
  .then((response) => {
    const user_membership = response.data;
		return dispatch({ type: JOIN_MEMBERSHIP, payload: user_membership });
  })
  .catch(function(error) {
    console.log(error);
  });
};

export const membershipStatus = (token,id) => (dispatch) => {
   return Axios.post(MEMBERSHIP_SUCCESS_URL, {
    id,token
  })
    .then((response) => {
      const membership_status = response.data;
      return dispatch({ type: MEMBERSHIP_SUCCESS, payload: membership_status });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getNearBrandStore = (id, latitude, longitude) => (dispatch) => {
  return Axios.post(GET_NEAR_BRAND_STORE_URL, {
    id,
    latitude,
    longitude,
  })
    .then((response) => {
      const brand_store = response.data;
      return dispatch({ type: GET_NEAR_BRAND_STORE, payload: brand_store });
    })
    .catch(function(error) {
      console.log(error);
    });
};
export const getSingleItem = (id, latitude, longitude) => (dispatch) => {
  return Axios.post(VIEW_SINGLE_ITEM_URL, {
    id,
    latitude,
    longitude,
  })
    .then((response) => {
      const item = response.data;
      return dispatch({ type: SINGLE_ITEM, payload: item });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getItemGroups = (latitude, longitude) => (dispatch) => {
  Axios.post(GET_ITEM_GROUPS_URL, {
    latitude: latitude,
    longitude: longitude,
  })
    .then((response) => {
      const item_groups = response.data;
      return dispatch({ type: GET_ITEM_GROUPS, payload: item_groups });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getItemBanners = (latitude, longitude) => (dispatch) => {
  Axios.post(GET_ITEM_BANNERS_URL, {
    latitude: latitude,
    longitude: longitude,
  })
    .then((response) => {
      const item_banners = response.data;
      return dispatch({ type: GET_ITEM_BANNERS, payload: item_banners });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getTrendingBrands = (latitude, longitude) => (dispatch) => {
  Axios.post(GET_TRENDING_BRANDS_URL, {
    latitude,
    longitude,
  })
    .then((response) => {
      const trending_brands = response.data;
      return dispatch({ type: GET_TRENDING_BRANDS, payload: trending_brands });
    })
    .catch(function(error) {
      console.log(error);
    });
};
export const getBrandStores = (brand_id, latitude, longitude) => (dispatch) => {
  return Axios.post(GET_BRAND_STORES, {
    brand_id,
    latitude,
    longitude,
  })
    .then((response) => {
      const brand = response.data;
      // console.log(brand);
      return dispatch({
        type: BRAND_STORES,
        payload: brand,
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getStoreSliders = (latitude, longitude) => (dispatch) => {
  Axios.post(GET_STORE_SLIDERS_URL, {
    latitude: latitude,
    longitude: longitude,
  })
    .then((response) => {
      const store_sliders = response.data;
      return dispatch({ type: GET_STORE_SLIDERS, payload: store_sliders });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getPopularItems = (latitude, longitude,type) => (dispatch) => {
 return Axios.post(GET_POPULAR_ITEMS_URL, {
    latitude: latitude,
    longitude: longitude,
    type:type
  })
    .then((response) => {
      const popular_items = response.data;
      return dispatch({ type: GET_POPULAR_ITEMS, payload: popular_items });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const getStores = (latitude, longitude) => (dispatch) => {
  return Axios.post(GET_STORES_URL, {
    latitude: latitude,
    longitude: longitude,
  })
    .then((response) => {
      const stores = response.data;
      return dispatch({ type: GET_STORES, payload: stores });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const searchItems = (search, latitude, longitude) => (dispatch) => {
  return Axios.post(SEARCH_ITEMS_URL, {
    search,
    latitude,
    longitude,
  })
    .then((response) => {
      const search = response.data;
      return dispatch({ type: SERACH_ITEMS, payload: search });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const viewSingleStore = (id) => (dispatch) => {
  return Axios.post(VIEW_SINGLE_STORE_URL, {
    id: id,
  })
    .then((response) => {
      const store = response.data;
      return dispatch({ type: SINGLE_STORE, payload: store });
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const scratchedReward = (id,token) => (dispatch) => {
  return Axios.post(SCRATCH_MILESTONE_URL, {
    token:token,
    milestone_id: id,
  })
    .then((response) => {
      const scratched_milestone = response.data;
      return dispatch({ type: SCRATCH_MILESTONE, payload: scratched_milestone });
    })
    .catch(function(error) {
      console.log(error);
    });
};


export const userMilestone = (token) => (dispatch) => {
  return Axios.post(USER_MILESTONE_URL, {
    token: token,
  })
    .then((response) => {
      const milestone = response.data;
      return dispatch({ type: USER_MILESTONE, payload: milestone });
    })
    .catch(function(error) {
      console.log(error);
    });
};