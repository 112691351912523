import { VIEW_BANNER_ITEMS } from "./actionType";

const initialState = {
    banner: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case VIEW_BANNER_ITEMS:
            return { ...state, banner: action.payload };
        default:
            return state;
    }
}