import {
  LOAD_CART,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  CALCULATE_STORE_MAX_DISTANCE,
  CALCULATE_STORE_STORE_CHARGE_TAX,
  GET_DELIVERY_SLAB,
  UPDATE_CURRENT_DELIVERY_SLAB,
  UPDATE_NEXT_DELIVERY_SLAB
} from "./actionTypes";
import Axios from "axios";
import {
  GET_CALCULATE_STORE_MAX_DISTANCE,
  GET_CALCULATE_STORE_STORE_CHARGE_TAX,
  GET_DELIVERY_SLAB_URL,
} from "../../configs";

export const loadCart = (products) => ({
  type: LOAD_CART,
  payload: products,
});

export const addProduct = (product) => ({
  type: ADD_PRODUCT,
  payload: product,
});

export const removeProduct = (product) => (dispatch) => {
  // console.log("From action", product);

  return dispatch({ type: REMOVE_PRODUCT, payload: product });
};

export const calcualteStoreMaxDistance = (id, products, address) => (
  dispatch
) => {
  return Axios.post(GET_CALCULATE_STORE_MAX_DISTANCE, {
    id,
    products,
    address,
  })
    .then((response) => {
      const max_distance = response.data;
      return dispatch({
        type: CALCULATE_STORE_MAX_DISTANCE,
        payload: max_distance,
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};
export const calculateStoreChargeTax = (products) => (dispatch) => {
  return Axios.post(GET_CALCULATE_STORE_STORE_CHARGE_TAX, {
    products,
  })
    .then((response) => {
      const charges = response.data;
      return dispatch({
        type: CALCULATE_STORE_STORE_CHARGE_TAX,
        payload: charges,
      });
    })
    .catch(function(error) {
      console.log(error);
    });
};
export const getDeliverySlab = (city_id, distance) => (dispatch) => {
  return Axios.post(GET_DELIVERY_SLAB_URL, {
    city_id,
    distance,
  })
    .then((response) => {
      const slabs = response.data;
      return dispatch({ type: GET_DELIVERY_SLAB, payload: slabs });
    })
    .catch(function(error) {
      console.log(error);
    });
};
export const updateSlabDetails = (current_slab,next_slab) => (dispatch) => {
	// console.log(current_slab)
   dispatch({ type: UPDATE_CURRENT_DELIVERY_SLAB, payload: current_slab });
   dispatch({ type: UPDATE_NEXT_DELIVERY_SLAB, payload: next_slab });
};
