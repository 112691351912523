import {
    SEND_CUSTOM_OTP,
    REGISTER_USER_OTP,
    LOGIN_OTP_USER,
    LOGOUT_USER,
    VIEW_ITEM_GROUP_ITEMS,
    UPDATE_USER_INFO,
    USER_WALLET,
    USER_UPDATE_USER_INFO,
    DELETE_USER,
} from "./actionType";

const initialState = {
    user: [],
    wallet: [],
    itemGroup: [],

};

export default function (state = initialState, action) {
    // console.log(action.payload, 'type');
    switch (action.type) {
        case SEND_CUSTOM_OTP:
            return { ...state, user: action.payload };
        case LOGIN_OTP_USER:
            return { ...state, user: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        case REGISTER_USER_OTP:
            return { ...state, user: action.payload };
        case DELETE_USER:
            return { ...state, user: action.payload };
        case VIEW_ITEM_GROUP_ITEMS:
            return { ...state, itemGroup: action.payload };
        case UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        case USER_WALLET:
            return { ...state, wallet: action.payload };
        case USER_UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        default:
            return state;
    }
}