import React, { Component } from "react";
import TextTruncate from "react-text-truncate";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import {
  WEBSITE_CDN_URL,
  WEBSITE_URL,
} from "../../../../configs/website/index";
import { Link } from "react-router-dom";
import { addProduct, removeProduct } from "../../../../services/cart/actions";
import FloatCart from "../../../Mobile/FloatCart";
import Customization from "../../../Mobile/Customization";
import notAvailable from "../../../../assets/png/no-items.png";

class Items extends Component {

  state = {
    update: true,
    brand: "ALL"
  };

  forceStateUpdate = () => {
    setTimeout(() => {
      this.forceUpdate();
      if (this.state.update) {
        this.setState({ update: false });
      } else {
        this.setState({ update: true });
      }
    }, 100);
  };

  onSelectBrand = (id) => {
    this.setState({ brand: id });
  };

  getRecommended = () => {
    let recommended = false;
    this.props.items.forEach(item => {
      if (item.is_brand_recomended) {
        recommended = true;
      }
    });
    return recommended;
  }

  render() {

    const { addProduct, removeProduct, cartProducts, items, brands } = this.props;
    const brand = this.state.brand;

    const colors = [
      "#FEEAC7",
      "#F4F99A",
      "#E4E1FF",
      "#EEFE9E",
      "#FFE9E9",
      "#FBB4B4",
    ];
    function random_item(items) {
      return items[Math.floor(Math.random() * items.length)];
    }

    return (
      <React.Fragment>
        <div className="mb-100">
          <div>
            {brands && brands.length > 0 && (
              <div className="mt-20">
                <div
                  className="mt-3 fw-600 user-select-none text-capitalize"
                  style={{ fontSize: "18px" }}
                >
                  Brands
                </div>
                <div
                  className="pb-0"
                  style={{
                    display: "flex",
                    gridTemplateRows: "1",
                    gridAutoFlow: "column",
                    overflowX: "scroll",
                    columnGap: "12px",
                    height: "auto",
                    alignItems: "center",
                  }}
                >
                  <div
                    key={'ALL'}
                    className="border-radius-10"
                    onClick={() => {
                      this.onSelectBrand('ALL');
                    }}
                  >
                    {/* <Ink duration={500} style={{ color: '#aaaaaa' }} /> */}
                    <div
                      className="position-relative d-flex flex-row justify-content-center align-items-center user-select-none "
                      style={{
                        width: "28vw",
                        height: "18vw",
                        backgroundColor: '#fbf8ac',
                        borderRadius: "1rem",
                        marginTop: "14vw",
                        border:
                          this.state.brand === 'ALL'
                            ? `1px solid #000`
                            : ""
                      }}>
                      <ProgressiveImage
                        delay={20}
                        src={WEBSITE_CDN_URL + localStorage.getItem('all_brands')}
                        placeholder={
                          "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                        }
                      >
                        {(src, loading) => (
                          <img
                            src={src}
                            className="position-absolute"
                            style={{
                              width: "20vw",
                              height: "20vw",
                              top: "-9vw",
                              objectFit: "cover",
                              position: "absolute",
                              borderRadius: "50%",
                              boxShadow:
                                "rgb(136 136 136) 0px 0px 10px -6px",
                              border: '3px solid #fff'
                            }}
                            alt={'ALL'}
                          />
                        )}
                      </ProgressiveImage>
                      <span
                        className="pt-5 text-truncate"
                        style={{
                          color: '#000',
                          fontWeight: "700",
                          fontSize: "11px",
                        }}>
                        ALL
                      </span>
                    </div>
                  </div>
                  {brands.map((item) => (
                    <div
                      key={item.id}
                      className=" border-radius-10"
                      onClick={() => {
                        this.onSelectBrand(item.id);
                      }}
                    >
                      {/* <Ink duration={500} style={{ color: '#aaaaaa' }} /> */}
                      <div
                        className="position-relative d-flex flex-row justify-content-center align-items-center user-select-none "
                        style={{
                          width: "28vw",
                          height: "18vw",
                          backgroundColor: item.image2 ? '' : item.popular_color_code,
                          backgroundImage: item.image2 ? `url(${WEBSITE_CDN_URL + item.image2})` : '',
                          borderRadius: "1rem",
                          marginTop: "14vw",
                          border:
                            this.state.brand === item.id
                              ? `1px solid ${item.popular_text_color}`
                              : ""
                        }}>
                        <ProgressiveImage
                          delay={20}
                          src={WEBSITE_CDN_URL + item.image}
                          placeholder={
                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                          }
                        >
                          {(src, loading) => (
                            <img
                              src={src}
                              className="position-absolute"
                              style={{
                                width: "20vw",
                                height: "20vw",
                                top: "-9vw",
                                objectFit: "contain",
                                position: "absolute",
                                borderRadius: "50%",
                                boxShadow:
                                  "rgb(136 136 136) 0px 0px 10px -6px",
                                border: '3px solid #fff'
                              }}
                              alt={item.name}
                            />
                          )}
                        </ProgressiveImage>
                        <span
                          className="pt-5 text-truncate"
                          style={{
                            color: item.popular_text_color,
                            fontWeight: "700",
                            fontSize: "11px",
                          }}>
                          {item.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {brand && this.getRecommended() &&
            <React.Fragment>
              {localStorage.getItem('recommended_filter_label') &&
                <img
                  src={'https://superjoe.in' + localStorage.getItem('recommended_filter_label')}
                  alt={'superjoe'}
                  style={{
                    height: "auto",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  className="mb-3"
                />
              }
              <div style={{ overflowX: "scroll" }}>
                <div
                  className=""
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(" +
                      items.length +
                      ",1fr)",
                    gap: "10px",
                    height: "auto",
                    alignItems: "center",
                  }}
                >
                  {items && items.length > 0 &&
                    items.map((item, index) => (
                      // <div key={item.id} className='pt-4'>
                      <React.Fragment key={item.id}>
                        {item &&
                          item.is_brand_recomended &&
                          (item.brand_id == brand || brand == 'ALL') ? (
                          <React.Fragment>
                            {(brand == item.brand_id) |
                              (brand == "ALL") ? (
                              <div
                                className="card"
                                style={{
                                  // height: "46vw",
                                  width: "40vw",
                                  borderRadius: "10px",
                                  marginTop: "23vw",
                                  border: "none",
                                  backgroundColor:
                                    item.popular_color_code,
                                  color:
                                    item.popular_text_color_code,
                                }}
                              >
                                <Link
                                  key={item.id}
                                  to={"/single-item/" + item.id}
                                >
                                  <ProgressiveImage
                                    delay={20}
                                    src={
                                      WEBSITE_CDN_URL + item.image
                                    }
                                    placeholder={
                                      "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                    }
                                  >
                                    {(src, loading) => (
                                      <img
                                        src={src}
                                        alt={item.name}
                                        style={{
                                          marginTop: "-70px",
                                          height: "40vw",
                                          width: "40vw",
                                          borderRadius: "10px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </ProgressiveImage>
                                </Link>
                                <div className="mx-3 pt-2 mb-2">
                                  <Link
                                    key={item.id}
                                    to={"/single-item/" + item.id}
                                  >
                                    <div>
                                      <div
                                        className=" user-select-none  text-truncate "
                                        style={{ fontWeight: "600" }}
                                      >
                                        {/* <Truncate lines={1} ellipsis={<span >...</span>}> */}
                                        {item.name}
                                        {/* </Truncate> */}
                                      </div>
                                      <div
                                        className="text-truncate user-select-none"
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item.store.name}
                                      </div>
                                      <div
                                        className="text-truncate user-select-none"
                                        style={{
                                          fontWeight: "600",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {item &&
                                          item.brand &&
                                          item.brand.name}
                                      </div>
                                      {item.selling_price > 0 ? (
                                        <React.Fragment>
                                          <div className="d-flex align-items-center justify-content-start flex-nowrap mt-2 ">
                                            <div
                                              style={{
                                                fontSize: "1em",
                                                fontWeight: "700",
                                                color:
                                                  item.popular_text_color_code,
                                              }}
                                            >
                                              ₹{item.selling_price}
                                            </div>
                                            {item.market_price &&
                                              item.market_price >
                                              0 && (
                                                <div className="ml-5">
                                                  <strike
                                                    className=""
                                                    style={{
                                                      fontSize:
                                                        "0.8em",
                                                      fontWeight:
                                                        "700",
                                                      color:
                                                        item.popular_text_color_code,
                                                    }}
                                                  >
                                                    ₹
                                                    {
                                                      item.market_price
                                                    }
                                                  </strike>
                                                </div>
                                              )}
                                          </div>
                                        </React.Fragment>
                                      ) : (
                                        <span className="customizable-item-text  user-select-none">
                                          Customizable
                                        </span>
                                      )}
                                    </div>
                                  </Link>
                                  <div className="mb-1 text-end">
                                    {cartProducts.find((cp) => cp.id === item.id) !==
                                      undefined && (
                                        <React.Fragment>
                                          <div className="mt-2">
                                            <div
                                              className="btn-group"
                                              role="group"
                                              aria-label="btnGroupIcons1"
                                            >
                                              {item.is_active ? (
                                                <React.Fragment>
                                                  {item.addoncategories.length ? null : (
                                                    <button
                                                      type="button"
                                                      className="btn-add-remove minus-btn py-2"
                                                      onClick={() => {
                                                        item.quantity = 1;
                                                        removeProduct(item);
                                                        this.forceStateUpdate();
                                                      }}
                                                    >
                                                      <span class="btn-dec">-</span>
                                                      {/* <Ink duration="500" /> */}
                                                    </button>
                                                  )}
                                                  {item.addoncategories.length ? null : (
                                                    <span className="btn-qnt py-2">
                                                      <React.Fragment>
                                                        {
                                                          cartProducts.find(
                                                            (cp) => cp.id === item.id
                                                          ).quantity
                                                        }
                                                      </React.Fragment>
                                                    </span>
                                                  )}

                                                  {item.addoncategories.length ? (
                                                    <Customization
                                                      product={item}
                                                      addProduct={addProduct}
                                                      forceUpdate={this.forceStateUpdate}
                                                    />
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="btn-add-remove plus-btn py-2"
                                                      onClick={() => {
                                                        addProduct(item);
                                                        this.forceStateUpdate();
                                                      }}
                                                    >
                                                      <span class="btn-dec">+</span>
                                                      {/* <Ink duration="500" /> */}
                                                    </button>
                                                  )}
                                                </React.Fragment>
                                              ) : (
                                                <div className="robo text-danger text-item-not-available">
                                                  Item Not Available
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      )}
                                    {cartProducts.find((cp) => cp.id === item.id) ===
                                      undefined && (
                                        <React.Fragment>
                                          <div className="mt-2">
                                            {item.is_active ? (
                                              <React.Fragment>
                                                {item.addoncategories.length ? (
                                                  <Customization
                                                    product={item}
                                                    addProduct={addProduct}
                                                    forceUpdate={this.forceStateUpdate}
                                                  />
                                                ) : (
                                                  <React.Fragment>
                                                    {item.stock > 0 ? (
                                                      <button
                                                        type="button"
                                                        className="py-2 text-center add-button"
                                                        onClick={() => {
                                                          item.quantity = 1;
                                                          addProduct(item);
                                                          this.forceStateUpdate();
                                                        }}
                                                      >
                                                        + ADD
                                                      </button>
                                                    ) : (
                                                      <button
                                                        type="button"
                                                        className="py-2 text-center add-button"
                                                        style={{
                                                          fontSize: "10px",
                                                          color: "#6c757d",
                                                          border: "1px solid #6c757d",
                                                        }}
                                                      >
                                                        NO STOCK
                                                      </button>
                                                    )}
                                                  </React.Fragment>
                                                )}
                                              </React.Fragment>
                                            ) : (
                                              <div className="robo text-danger text-item-not-available">
                                                <img src={notAvailable} alt="superjoe" />
                                              </div>
                                            )}
                                          </div>
                                        </React.Fragment>
                                      )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                      // </div>
                    ))}
                </div>
              </div>
            </React.Fragment>
          }
          {items && items.length > 0 ? (
              items.map((item, index) => (
                <React.Fragment>
                  {(brand == item.brand_id || brand == 'ALL') &&
                    <div className="" key={item.id} id={item.id + index}>
                      <div
                        className="mt-4 border-radius-10 py-3 px-3"
                        style={{
                          backgroundColor: "#fff",
                          border: "none",
                          outline: "none",
                          boxShadow: "rgb(136 136 136) 0px 0px 10px -7px",
                        }}
                      >
                        <div className="d-flex flex-row align-items-center justify-content-between">
                          <div className="flex-shrink-1">
                            <Link to={"/single-item/" + item.id}>
                              {item.image ? (
                                <ProgressiveImage
                                  delay={100}
                                  src={WEBSITE_CDN_URL + "/" + item.image}
                                  placeholder={
                                    "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                                  }
                                >
                                  {(src, loading) => (
                                    <img
                                      src={src}
                                      style={{
                                        height: "13vh",
                                        width: "25vw",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                      }}
                                      alt={"item"}
                                    />
                                  )}
                                </ProgressiveImage>
                              ) : (
                                <div
                                  style={{
                                    height: "13vh",
                                    width: "25vw",
                                    borderRadius: "10px",
                                    objectFit: "cover",
                                  }}
                                >
                                  {" "}
                                </div>
                              )}
                            </Link>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <div className="d-flex flex-column align-items-start justify-content-center">
                              <Link to={"/single-item/" + item.id}>
                                <div>
                                  <div className="d-flex flex-row align-items-center ">
                                    <span className="fw-bdr fs-5 user-select-none text-capitalize">
                                      <TextTruncate
                                        line={2}
                                        element="span"
                                        truncateText="…"
                                        text={item.name}
                                      />
                                    </span>
                                    {item.is_new ? (
                                      <div
                                        className=" badge badge-primary ms-1 px-3 py-2 fs-7 border-radius-10"
                                        style={{
                                          backgroundColor: "#9aafff",
                                          color: "#edf0ff",
                                        }}
                                      >
                                        New
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                  <div className="d-flex flex-row align-items-center text-truncate">
                                    <div className="fw-bdr fs-7 user-select-none text-capitalize">
                                      {item.store&&item.store.name}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row align-items-center text-truncate">
                                    <div className="fw-bdr fs-7 user-select-none ">
                                      {item && item.brand && item.brand.name}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              {item.selling_price > 0 ? (
                                <React.Fragment>
                                  <div>
                                    <span className="fw-bd">
                                      ₹&nbsp;&nbsp;&nbsp;{item.selling_price}
                                    </span>
                                    {item.market_price > item.selling_price && (
                                      <strike className="text-muted fs-xsm ms-3 user-select-none">
                                        ₹&nbsp;&nbsp;&nbsp;{item.market_price}
                                      </strike>
                                    )}
                                  </div>
                                  <div>
                                    {item.market_price > item.selling_price && (
                                      <span
                                        className="fw-bd "
                                        style={{ color: "#45ac00" }}
                                      >
                                        {Math.round(
                                          ((item.market_price - item.selling_price) /
                                            item.market_price) *
                                          100
                                        )}{" "}
                                        % off
                                      </span>
                                    )}
                                  </div>
                                </React.Fragment>
                              ) : (
                                <span className="customizable-item-text">
                                  Customizable
                                </span>
                              )}
                              <div>
                                {cartProducts.find((cp) => cp.id === item.id) !==
                                  undefined && (
                                    <React.Fragment>
                                      <div className="mt-2">
                                        <div
                                          className="btn-group"
                                          role="group"
                                          aria-label="btnGroupIcons1"
                                        >
                                          {item.is_active ? (
                                            <React.Fragment>
                                              {item.addoncategories.length ? null : (
                                                <button
                                                  type="button"
                                                  className="btn-add-remove minus-btn py-2"
                                                  onClick={() => {
                                                    item.quantity = 1;
                                                    removeProduct(item);
                                                    this.forceStateUpdate();
                                                  }}
                                                >
                                                  <span class="btn-dec">-</span>
                                                  {/* <Ink duration="500" /> */}
                                                </button>
                                              )}
                                              {item.addoncategories.length ? null : (
                                                <span className="btn-qnt py-2">
                                                  <React.Fragment>
                                                    {
                                                      cartProducts.find(
                                                        (cp) => cp.id === item.id
                                                      ).quantity
                                                    }
                                                  </React.Fragment>
                                                </span>
                                              )}

                                              {item.addoncategories.length ? (
                                                <Customization
                                                  product={item}
                                                  addProduct={addProduct}
                                                  forceUpdate={this.forceStateUpdate}
                                                />
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-add-remove plus-btn py-2"
                                                  onClick={() => {
                                                    addProduct(item);
                                                    this.forceStateUpdate();
                                                  }}
                                                >
                                                  <span class="btn-dec">+</span>
                                                  {/* <Ink duration="500" /> */}
                                                </button>
                                              )}
                                            </React.Fragment>
                                          ) : (
                                            <div className="robo text-danger text-item-not-available">
                                              Item Not Available
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                {cartProducts.find((cp) => cp.id === item.id) ===
                                  undefined && (
                                    <React.Fragment>
                                      <div className="mt-2">
                                        {item.is_active ? (
                                          <React.Fragment>
                                            {item.addoncategories.length ? (
                                              <Customization
                                                product={item}
                                                addProduct={addProduct}
                                                forceUpdate={this.forceStateUpdate}
                                              />
                                            ) : (
                                              <React.Fragment>
                                                {item.stock > 0 ? (
                                                  <button
                                                    type="button"
                                                    className="py-2 text-center add-button"
                                                    onClick={() => {
                                                      item.quantity = 1;
                                                      addProduct(item);
                                                      this.forceStateUpdate();
                                                    }}
                                                  >
                                                    + ADD
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="py-2 text-center add-button"
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "#6c757d",
                                                      border: "1px solid #6c757d",
                                                    }}
                                                  >
                                                    NO STOCK
                                                  </button>
                                                )}
                                              </React.Fragment>
                                            )}
                                          </React.Fragment>
                                        ) : (
                                          <div className="robo text-danger text-item-not-available">
                                            <img src={notAvailable} alt="superjoe" />
                                          </div>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  )}
                              </div>
                              <div className="">
                                <div className="d-flex flex-row flex-wrap justify-content-start align-items-center">
                                  {item.is_popular ? (
                                    <span
                                      className="mt-2 badge badge-success px-3 py-2 fs-7 border-radius-10"
                                      style={{
                                        backgroundColor: "#b1e693",
                                        color: "#12610d",
                                      }}
                                    >
                                      Popular
                                    </span>
                                  ) : (
                                    <span></span>
                                  )}
                                  {item.is_recomended ? (
                                    <span
                                      className="mt-2 badge badge-info ms-1 px-3 py-2 fs-7 border-radius-10"
                                      style={{
                                        backgroundColor: "#abd5ff",
                                        color: "#0c568e",
                                      }}
                                    >
                                      Recommended
                                    </span>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <img
                    src={notAvailable}
                    style={{ height: "45vh" }}
                    alt="superjoe"
                  />
                </div>
              </React.Fragment>
            )
          }
        </div>
        <FloatCart />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartProducts: state.cart.products,
});

export default connect(mapStateToProps, {
  addProduct,
  removeProduct,
})(Items);
