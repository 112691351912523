import React, { Component } from "react";
import { connect } from "react-redux";
import {
  WEBSITE_CDN_URL,
  WEBSITE_URL,
} from "../../../../configs/website/index";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { getItemGroups } from "../../../../services/home/actions";
import ContentLoader from "react-content-loader";
import HoveredBackButton from "../../../Elements/HoveredBackButton";

class ItemGroups extends Component {
  componentDidMount() {
    this.props.getItemGroups(
      JSON.parse(localStorage.getItem("userSetAddress")).lat,
      JSON.parse(localStorage.getItem("userSetAddress")).lng
    );
  }

  render() {
    const { item_groups } = this.props;
    return (
      <React.Fragment>
        {item_groups && item_groups.item_groups ? (
          <React.Fragment>
            {item_groups.item_groups.length > 0 && (
              <div className="mt-20">
                <div className="slider-wrapper">
                  {item_groups.item_groups.map((item) => (
                    <div
                      key={item.id}
                      style={{ display: "inline-block", marginRight: "3vw" }}
                    >
                      <Link to={"/item-group/" + item.id}>
                        <ProgressiveImage
                          delay={20}
                          src={WEBSITE_CDN_URL + item.image}
                          placeholder={
                            "https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder"
                          }
                        >
                          {(src, loading) => (
                            <img
                              src={src}
                              style={{
                                width: "37vw",
                                minHeight: "55vw",
                                objectFit: "cover",
                                borderRadius: "1rem",
                              }}
                              alt={item.name}
                            />
                          )}
                        </ProgressiveImage>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <>
            <HoveredBackButton />
            <ContentLoader
              width={420}
              // height={10}
              viewBox="0 0 420 220"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              {/* Only SVG shapes */}
              <rect x="0" y="10" rx="5" ry="5" width="37vw" height="10vw" />
              <rect x="160" y="10" rx="5" ry="5" width="37vw" height="10vw" />
              <rect x="320" y="10" rx="5" ry="5" width="37vw" height="10vw" />

              <rect x="0" y="60" rx="15" ry="15" width="37vw" height="35vw" />
              <rect x="160" y="60" rx="15" ry="15" width="37vw" height="35vw" />
              <rect x="320" y="60" rx="15" ry="15" width="37vw" height="35vw" />
            </ContentLoader>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  item_groups: state.home.item_groups,
});

export default connect(mapStateToProps, {
  getItemGroups,
})(ItemGroups);
