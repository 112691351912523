import {
    VIEW_SLIDER_STORES
} from "./actionType";

const initialState = {
    slider: [],
    

};

export default function (state = initialState, action) {
    // console.log(action.payload, 'type');
    switch (action.type) {
        
        case VIEW_SLIDER_STORES:
            return { ...state, slider: action.payload };
        default:
            return state;
    }
}