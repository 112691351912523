import React, { Component } from 'react';
import Truncate from 'react-truncate';
import { Star, TimeCircle } from 'react-iconly';
import { WEBSITE_CDN_URL, WEBSITE_URL } from '../../../../configs/website/index';
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import { MdTimer } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
import { ImPriceTags } from 'react-icons/im';

class Stores extends Component {
    getMarkedup = (description) => {
        return { __html: description };
    }
    render() {

        const { stores } = this.props;

        return (
            <React.Fragment>
                {stores.map((item, index) =>
                    <div
                        key={item.id}
                        id={item.id + index}
                    >
                        <Link to={'/single-store/' + item.id}>
                            <div className="d-flex flex-column mt-3" style={{ boxShadow: '0px 0px 5px #b0b0b0', borderRadius: '10px' }}>
                                <div>
                                    <Carousel centerMode={false} showIndicators={false} dots={false} showThumbs={false} centerSlidePercentage={95} infiniteLoop={false} labels={false} showStatus={false} showArrows={false}>

                                        <ProgressiveImage
                                            delay={20}
                                            src={WEBSITE_CDN_URL + item.image}
                                            placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    className=""
                                                    alt={item.name}
                                                    style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                />
                                            )}
                                        </ProgressiveImage>

                                        <ProgressiveImage
                                            delay={20}
                                            src={WEBSITE_CDN_URL + item.image2}
                                            placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    className=""
                                                    alt={item.name}
                                                    style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                />
                                            )}
                                        </ProgressiveImage>

                                        <ProgressiveImage
                                            delay={20}
                                            src={WEBSITE_CDN_URL + item.image3}
                                            placeholder={'https://res.cloudinary.com/swiggy/image/upload/fl_lossy,f_auto,q_auto/portal/m/blue_placeholder'}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    className=""
                                                    alt={item.name}
                                                    style={{ width: '100%', height: '40vw', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </Carousel>
                                </div>

                                <div className="d-flex flex-column px-3 py-3" style={{ backgroundColor: '#e8e8e8', borderRadius: '0px 0px 10px 10px' }}>
                                    <div className="" style={{ fontSize: '1em' }}>
                                        <div className='d-flex flex-row justify-content-around align-items-center'>
                                            <div className='flex-shrink-1'>

                                                <span className='fw-bold ' style={{ fontSize: '1.1em' }}>{item.name} </span>
                                            </div>
                                            <div className='flex-grow-1 ms-2 mt-3 text-truncate'>
                                                <span className='' style={{ fontSize: '0.8em' }} dangerouslySetInnerHTML={this.getMarkedup(item.description)}>
                                                </span>
                                            </div>
                                        </div>

                                        {/* <Truncate lines={1} ellipsis={<span >...</span>}> */}

                                        {/* </Truncate> */}

                                    </div>
                                    <div className='d-flex flex-row align-items-center mt-2'>

                                        <div className="px-2 py-1" style={{ fontSize: '.8em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                            {item.avg_time ?
                                                <div className='' >
                                                    <MdTimer className="" style={{ fontSize: '1.2em', color: '#ed1c24' }} />
                                                    <span style={{ color: '#342e86' }}> {item.avg_time} Minutes</span>
                                                </div>
                                                :

                                                <div className='' >

                                                    <span style={{ color: '#342e86' }} ><MdTimer className="" style={{ fontSize: '1.2em', color: '#ed1c24' }} /> 30 Minutes</span>
                                                </div>
                                            }
                                        </div>
                                        {item.rating ?
                                            <div className="px-2 py-1 ms-3" style={{ fontSize: '.8em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                {item.rating <= 1.9 ?
                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                    :
                                                    item.rating <= 2.9 ?
                                                        <>

                                                            <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                            <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                        </>
                                                        :
                                                        item.rating <= 3.9 ?
                                                            <>
                                                                <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                            </>
                                                            :
                                                            item.rating <= 4.9 ?
                                                                <>
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                </>
                                                                :
                                                                <>
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                    <AiFillStar style={{ fontSize: '.8em', color: '#ed1c24' }} />
                                                                </>
                                                }


                                            </div>
                                            :
                                            <>

                                            </>
                                        }


                                        <div className="px-2 py-1 text-center ms-3" style={{ fontSize: '.8em', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                            <span style={{ color: '#342e86' }}><ImPriceTags style={{ fontSize: '1em', color: '#ed1c24' }} />  Popular</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
                }
            </React.Fragment>
        );
    }
}

export default Stores;